import React, { SVGProps, useId } from 'react';

export const Spell = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 10.4072 1.06318 12.5661 2.74558 14.0327C2.31652 14.1452 2 14.5356 2 15C2 15.5523 2.44772 16 3 16H8H13C13.5523 16 14 15.5523 14 15C14 14.5356 13.6835 14.1452 13.2544 14.0327C14.9368 12.5661 16 10.4072 16 8C16 3.58172 12.4183 0 8 0ZM6 9C5.44772 9 5 9.44771 5 10C5 10.5523 5.44772 11 6 11C6.55228 11 7 10.5523 7 10C7 9.44771 6.55228 9 6 9ZM9 8C9 8.55228 9.44772 9 10 9C10.5523 9 11 8.55228 11 8V7H12C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5H11V4C11 3.44772 10.5523 3 10 3C9.44772 3 9 3.44772 9 4V5H8C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H9V8Z"
        fill={`url(#${gradientId})`}
      />
      <linearGradient
        id={gradientId}
        x1="12"
        y1="2.27985e-08"
        x2="7.86811"
        y2="15.9659"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8066" />
        <stop offset="0.5" stopColor="#E666FF" />
        <stop offset="1" stopColor="#66B3FF" />
      </linearGradient>
    </svg>
  );
};
