import React, { SVGProps, useId } from 'react';

export const Banxa = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g clipPath={`url(#${gradientId})`}>
        <path
          d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
          fill="#111630"
        />
        <path
          d="M25.2237 23.4394H6.78139C6.46889 23.4417 6.16137 23.3611 5.8901 23.2059C5.61884 23.0507 5.39363 22.8263 5.23722 22.5558C5.0809 22.2851 4.99906 21.9779 5.00001 21.6654C5.00104 21.3529 5.08477 21.0462 5.24283 20.7766L13.9613 5.67668C14.168 5.3189 14.4652 5.02182 14.823 4.81528C15.1809 4.60873 15.5868 4.5 16 4.5C16.4132 4.5 16.8192 4.60873 17.177 4.81528C17.5348 5.02182 17.8321 5.3189 18.0387 5.67668L26.7572 20.7766C26.9152 21.0462 26.999 21.3529 27 21.6654C27.001 21.9779 26.9191 22.2851 26.7628 22.5558C26.6064 22.8263 26.3812 23.0507 26.1099 23.2059C25.8387 23.3611 25.5311 23.4417 25.2186 23.4394H25.2237ZM9.86103 19.8899H22.1492L16.0051 9.2464L9.86103 19.8899Z"
          fill={`url(#${gradientId})`}
        />
      </g>
      <linearGradient
        id={gradientId}
        x1="8.5397"
        y1="25.9253"
        x2="23.4729"
        y2="10.9921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0073D1" />
        <stop offset="0.06" stopColor="#0082CE" />
        <stop offset="0.22" stopColor="#00A0C9" />
        <stop offset="0.39" stopColor="#00B8C4" />
        <stop offset="0.57" stopColor="#00C9C1" />
        <stop offset="0.76" stopColor="#00D3C0" />
        <stop offset="1" stopColor="#00D6BF" />
      </linearGradient>
    </svg>
  );
};
